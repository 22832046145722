import * as React from 'react';

export function usePreviousValue<T>(
  value: T,
  initialValue: T | null = null,
): T | null {
  const prevVal = React.useRef<T | null>(initialValue);
  React.useEffect(() => {
    prevVal.current = value;
  }, [value]);

  return prevVal.current;
}
