export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <g clipPath="url(#overcast-clippath)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 48C10.7 48 0 37.3 0 24S10.7 0 24 0s24 10.7 24 24-10.7 24-24 24zm0-9.5l1.8-1.8-1.8-6.9-1.8 6.9 1.8 1.8zm-2.3-.1l-.9 3.4 2.1-2.1-1.2-1.3zm4.6 0l-1.2 1.2 2.1 2.1-.9-3.3zm1.7 6.2l-4-4-4 4c1.3.2 2.6.4 4 .4s2.7-.1 4-.4zM24 3A21 21 0 003 24c0 9.2 5.9 17 14.1 19.8l4.5-16.7a4.01 4.01 0 012.4-7.2c2.2 0 4 1.8 4 4 0 1.3-.6 2.4-1.6 3.2l4.5 16.7A20.9 20.9 0 0045 24 21 21 0 0024 3zm13.7 31.4c-.5.6-1.4.8-2.1.3-.7-.5-.8-1.4-.3-2 0 0 2.7-3.6 2.7-8.7s-2.7-8.7-2.7-8.7c-.5-.6-.4-1.5.3-2s1.6-.3 2.1.3c.2.2 3.3 4.3 3.3 10.4 0 6.1-3.1 10.2-3.3 10.4zm-7.3-3.9c-.6-.6-.5-1.7.1-2.3 0 0 1.5-1.6 1.5-4.2s-1.5-4.1-1.5-4.2c-.6-.6-.6-1.6-.1-2.3.6-.7 1.5-.7 2.1-.1a9.97 9.97 0 010 13.2c-.6.6-1.5.5-2.1-.1zm-12.8 0c-.6.6-1.5.7-2.1.1-.1-.2-2.5-2.6-2.5-6.6 0-4 2.4-6.4 2.5-6.6.6-.6 1.6-.6 2.1.1.6.6.5 1.7-.1 2.3 0 .1-1.5 1.6-1.5 4.2s1.5 4.2 1.5 4.2c.6.6.7 1.6.1 2.3zm-5.2 4.2c-.7.5-1.6.3-2.1-.3C10.1 34.2 7 30.1 7 24c0-6.1 3.1-10.2 3.3-10.4.5-.6 1.4-.8 2.1-.3.7.5.8 1.4.3 2 0 0-2.7 3.6-2.7 8.7s2.7 8.7 2.7 8.7c.5.6.3 1.5-.3 2z"
        fill="#FC7E0F"
      />
    </g>
    <defs>
      <clipPath id="overcast-clippath">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
