import * as React from 'react';
import {render} from 'react-dom';

import {DarkModeProvider} from '@pinecast/styles/colors';
import {Provider as I18nProvider} from '@pinecast/i18n';
import ShareShortLink from '@pinecast/sl-spa/ShareShortLink';
import {Provider as StyletronProvider} from '@pinecast/styles/provider';

render(
  <React.StrictMode>
    <I18nProvider>
      <StyletronProvider>
        <DarkModeProvider>
          <ShareShortLink />
        </DarkModeProvider>
      </StyletronProvider>
    </I18nProvider>
  </React.StrictMode>,
  document.querySelector('.omnibus'),
);
