export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.77 21.47c-7.58-4.5-20.07-4.92-27.3-2.72a2.2 2.2 0 11-1.28-4.21c8.3-2.52 22.1-2.03 30.83 3.14a2.2 2.2 0 01-2.25 3.79zm-.25 6.66a1.83 1.83 0 01-2.52.6c-6.31-3.88-15.95-5-23.42-2.74a1.84 1.84 0 01-1.07-3.5c8.54-2.6 19.16-1.34 26.41 3.12.86.53 1.13 1.66.6 2.52zm-4.89 6.89a1.46 1.46 0 101.53-2.5c-6.2-3.79-13.88-4.67-22.83-2.62a1.46 1.46 0 10.65 2.85c8.18-1.87 15.13-1.1 20.65 2.27zM23.87.62a23.5 23.5 0 100 47.02 23.5 23.5 0 000-47.01z"
      fill="#1ED760"
    />
  </svg>
);
