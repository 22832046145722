import {ShareShortLink_podcast} from '@pinecast/gql/__generated_sl__/ShareShortLink';

import getPreloadedData from './getPreloadedData';
import PodcastChrome from './PodcastChrome';
// import {
//   QUERY_SHARE_SHORTLINK,
//   usePreloadedQueries,
// } from './usePreloadedQueriesPodcast';

const ShareShortLink = () => {
  // const preload = usePreloadedQueries(QUERY_SHARE_SHORTLINK, slug);
  // return <PodcastChrome podcast={preload.share.data!.podcast!} />;
  const podcast = getPreloadedData().share.podcast as ShareShortLink_podcast;
  return <PodcastChrome podcast={podcast} />;
};

export default ShareShortLink;
