import {
  FormattedMessage as OrigFormattedMessage,
  IntlProvider,
} from 'react-intl';
import * as React from 'react';

export type {IntlShape} from 'react-intl';
export {useIntl} from 'react-intl';

import type {ElementConfig} from '@pinecast/common/types';
import {InlineCode} from '@pinecast/common/Text';

export const Provider = ({
  children,
  ...rest
}: Partial<ElementConfig<typeof IntlProvider>>) => (
  <IntlProvider
    locale="en"
    defaultRichTextElements={{
      b: c => <b>{c}</b>,
      code: c => <InlineCode>{c}</InlineCode>,
    }}
    {...rest}
  >
    {children}
  </IntlProvider>
);

type ValueType =
  | React.ReactNode
  | ((chunks: React.ReactNode) => React.ReactNode);

interface ExtendedProps {
  id: string;
  description?: string;
  defaultMessage: string;
  values?: Record<string, ValueType>;
  className?: string;
  style?: React.CSSProperties;
}

export const FormattedMessage = ({className, style, ...rest}: ExtendedProps) =>
  style || className ? (
    <OrigFormattedMessage {...rest}>
      {content => (
        <span className={className} style={style}>
          {content}
        </span>
      )}
    </OrigFormattedMessage>
  ) : (
    <OrigFormattedMessage {...rest} />
  );

export type Message = {
  id: string;
  description: string;
  defaultMessage: string;
};

// This weird typing is to preserve autocomplete
export function defineMessages<T extends Record<string, any>>(
  messages: {[id in keyof T]: Message},
): {[id in keyof T]: Message} {
  return messages;
}
