export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <path fill="#fff" d="M6.88 11.13h34.23v24.31H6.88z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 .27a2 2 0 00-2 0l-19 11A2 2 0 003 13v22a2 2 0 001 1.73l19 11a2 2 0 002 0l19-11A2 2 0 0045 35V13a2 2 0 00-1-1.73l-19-11zm2.7 18.66a1.4 1.4 0 00-1.42-1.38 1.4 1.4 0 00-1.42 1.38v3.33c0 .42-.39.76-.86.76s-.86-.34-.86-.76v-4.53a1.4 1.4 0 00-1.42-1.38 1.4 1.4 0 00-1.43 1.38v3.63c0 .42-.38.76-.86.76-.47 0-.85-.34-.85-.76v-1.32a1.4 1.4 0 00-1.43-1.38 1.4 1.4 0 00-1.42 1.38v3.12c0 .42-.39.76-.86.76s-.85-.34-.85-.76v-.36c0-.8-.64-1.45-1.43-1.45-.79 0-1.43.65-1.43 1.45v4.32c0 .8.64 1.44 1.43 1.44.79 0 1.43-.64 1.43-1.44v-.36c0-.42.38-.76.85-.76s.86.34.86.76v4.94c0 .76.64 1.38 1.42 1.38a1.4 1.4 0 001.43-1.38v-6.74c0-.42.38-.76.86-.76.47 0 .85.34.85.76v3.4c0 .76.64 1.37 1.43 1.37a1.4 1.4 0 001.42-1.37v-2.5c0-.42.39-.76.86-.76s.86.34.86.76v2.67c0 .76.64 1.37 1.42 1.37a1.4 1.4 0 001.43-1.37v-9.6zm3.15 2.34c.78 0 1.42.62 1.42 1.38v5.48a1.4 1.4 0 01-1.42 1.38 1.4 1.4 0 01-1.43-1.38v-5.48c0-.76.64-1.38 1.43-1.38zm5.99 4.16a1.4 1.4 0 00-1.43-1.37A1.4 1.4 0 0034 25.43v1.37c0 .76.64 1.38 1.42 1.38a1.4 1.4 0 001.43-1.38v-1.37z"
      fill="#F55B23"
    />
  </svg>
);
