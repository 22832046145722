import * as React from 'react';
import * as ReactDOM from 'react-dom';

type Props = {
  children: React.ReactNode;
  pointerEvents?: boolean;
  position?: 'absolute' | 'fixed';
};

const lastChild = document.querySelector('body > footer');

const Layer = ({
  children,
  pointerEvents = true,
  position = 'absolute',
}: Props) => {
  const portal = React.useRef<HTMLDivElement>();
  if (!portal.current) {
    portal.current = document.createElement('div');
  }

  React.useEffect(() => {
    if (!portal.current) return;
    portal.current.style.pointerEvents = pointerEvents ? 'all' : 'none';
    portal.current.style.position = position;
    portal.current.style.left = '0';
    portal.current.style.top = '0';
    portal.current.style.zIndex = '100';
  }, [pointerEvents, position]);

  React.useEffect(() => {
    if (!portal.current) return;
    if (lastChild) {
      document.body.insertBefore(portal.current, lastChild);
    } else {
      document.body.appendChild(portal.current);
    }
    return () => {
      if (!portal.current) return;
      document.body.removeChild(portal.current);
    };
  }, []);

  return ReactDOM.createPortal(children, portal.current);
};

export default Layer;

export const CloseableLayer = ({
  onClose,
  ...rest
}: Props & {onClose: () => void}) => {
  React.useEffect(() => {
    const escListener = (e: KeyboardEvent) => {
      if (e.keyCode !== 27) {
        // ESC
        return;
      }
      onClose();
    };
    window.addEventListener('keyup', escListener);
    return () => {
      window.removeEventListener('keyup', escListener);
    };
  }, [onClose]);

  return <Layer {...rest} />;
};
