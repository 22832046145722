import * as React from 'react';

import {useEventBus} from '../hooks/useEventBus';
import {usePreviousValue} from '../hooks/usePreviousValue';

export function namespace(nsKey: string, version: number = 1) {
  function formatKey(key: string): string {
    return `${version}.${nsKey}.${key}`;
  }

  function get(key: string, def: string): string;
  function get(key: string): string | null;
  function get(key: string, def: string | null = null): string | null {
    try {
      return localStorage.getItem(formatKey(key)) ?? def ?? null;
    } catch {
      return def ?? null;
    }
  }

  function set(key: string, value: string | null) {
    try {
      if (value === null) {
        localStorage.removeItem(formatKey(key));
      } else {
        localStorage.setItem(formatKey(key), value);
      }
    } catch (e) {
      console.error(e);
    }
  }

  function usePersist<T extends string>(
    key: string,
    def: T | (() => T),
  ): [T, (value: T) => void];
  function usePersist<T>(
    key: string,
    def: T | (() => T),
    encode: (value: T) => string,
    decode: (value: string) => T,
  ): [T, (value: T) => void];
  function usePersist<T>(
    key: string,
    def: T | (() => T),
    encode: (value: T) => string = String,
    decode: (value: string) => T = (x): any => x,
  ): [T, (value: T) => void] {
    const [value, setValue] = React.useState<T>(
      () => decode(get(key, encode(evalLazyDef(def)))) as T,
    );
    const broadcast = useEventBus(
      `persist.${formatKey(key)}`,
      React.useCallback(() => {
        setValue(decode(get(key, encode(evalLazyDef(def)))));
      }, [key]), // eslint-disable-line react-hooks/exhaustive-deps
    );

    const previousKey = usePreviousValue(key);
    React.useEffect(() => {
      if (!previousKey) {
        return;
      }
      setValue(decode(get(key, encode(evalLazyDef(def)))));
    }, [key, previousKey]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSetValue = React.useCallback(
      (value: T) => {
        setValue(value);
        set(key, encode(value));
        broadcast();
      },
      [encode, key, broadcast],
    );
    return [value, handleSetValue];
  }

  return {
    get,
    set,
    usePersist,
  };
}

function evalLazyDef<T>(def: T | (() => T)): T {
  if (typeof def === 'function') {
    return (def as () => T)();
  }
  return def;
}
