import {namespace} from '../helpers/persist';

const darkModeNS = namespace('darkMode');

export const useDarkModeState = () => {
  return darkModeNS.usePersist<boolean | null>(
    'enable',
    null,
    (x: boolean | null) => JSON.stringify(x),
    (x: string) => JSON.parse(x),
  );
};

export const useIsDarkMode = () => {
  const [isDarkMode] = useDarkModeState();
  if (isDarkMode != null) {
    return isDarkMode;
  }
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
};
