export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <circle cx="24.35" cy="24.35" fill="#fff" r="22.94" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 24a24 24 0 11-48 0 24 24 0 0148 0zm-36.88-.07a12.8 12.8 0 1125.61 0h4.77A17.57 17.57 0 1023.93 41.5v-4.77a12.8 12.8 0 01-12.8-12.8zm12.92-6.74a6.85 6.85 0 000 13.7v3.65a10.5 10.5 0 1110.5-10.5H30.9a6.85 6.85 0 00-6.85-6.85z"
      fill="#F44336"
    />
  </svg>
);
