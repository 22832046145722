/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PodcastDirectoryEnum {
  AMAZON = 'AMAZON',
  APPLE = 'APPLE',
  BOOMPLAY = 'BOOMPLAY',
  IHEARTRADIO = 'IHEARTRADIO',
  JIOSAAVN = 'JIOSAAVN',
  LISTEN_NOTES = 'LISTEN_NOTES',
  POCKETCASTS = 'POCKETCASTS',
  PODCHASER = 'PODCHASER',
  STITCHER = 'STITCHER',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
