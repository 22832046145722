export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.95 10.14c0-.83.68-1.5 1.5-1.5H45.2c.83 0 1.5.67 1.5 1.5v20.74c0 .83-.67 1.5-1.5 1.5H25.05v5.48c0 .83-.67 1.5-1.5 1.5H2.8a1.5 1.5 0 01-1.5-1.5V17.12c0-.83.67-1.5 1.5-1.5h20.14v-5.48zm9.78 5.98c0-.27.22-.5.5-.5h3.89c.27 0 .5.23.5.5v1.1a.5.5 0 01-.5.5h-.9v5.58h.9c.27 0 .5.23.5.5v1.1a.5.5 0 01-.5.5h-3.9a.5.5 0 01-.5-.5v-1.1c0-.27.23-.5.5-.5h.9v-5.58h-.9a.5.5 0 01-.5-.5v-1.1zm-9.78 1.6H3.4v19.55h19.55V17.72zM10.2 22.6a.5.5 0 00-.5.5v1.1c0 .27.22.5.5.5h1.6v7.18c0 .27.22.5.5.5h1.09a.5.5 0 00.5-.5V24.7h1.6a.5.5 0 00.5-.5v-1.1a.5.5 0 00-.5-.5h-5.3z"
      fill="#1C203C"
    />
  </svg>
);
