import * as PopperJS from '@popperjs/core';
import * as React from 'react';
import {usePopper} from 'react-popper';

export type XAlign = 'left' | 'right';
export type YAlign = 'top' | 'bottom';

type Props = {
  allowTransition?: boolean;
  children: (
    ref: (el: HTMLElement | null) => void,
    styles: React.CSSProperties,
    xAlign: XAlign,
    yAlign: YAlign,
  ) => JSX.Element;
  content?: JSX.Element;
  describedBy?: string;
  labelledBy?: string;
  preferX?: XAlign;
  preferY?: YAlign;
  referenceElementType?: string;
  referenceElementProps?: Record<string, any>;
  style?: React.CSSProperties;
  offsetSkidding?: number;
  offsetDistance?: number;
  updateKey?: string;
};

export const Positioner = (props: Props) => {
  const [
    referenceElement,
    setReferenceElement,
  ] = React.useState<Element | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null,
  );
  const offsetFunc = React.useCallback(
    ({placement}: {placement: string}) => {
      const out: [number, number] = [
        props.offsetSkidding || 0,
        props.offsetDistance || 0,
      ];
      if (placement.endsWith('-end')) {
        out[0] *= -1;
      }
      return out;
    },
    [props.offsetDistance, props.offsetSkidding],
  );
  const modifiers: Array<Partial<
    PopperJS.Modifier<'offset' | 'computeStyles', any>
  >> = [
    {
      name: 'offset',
      options: {
        offset: offsetFunc,
      },
    },
  ];
  if (props.allowTransition) {
    modifiers.push({
      name: 'computeStyles',
      options: {adaptive: false},
    });
  }
  const {styles, attributes, update} = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placementFromPreferences(
        props.preferX || 'left',
        props.preferY || 'bottom',
      ),
      modifiers,
    },
  );

  React.useLayoutEffect(() => {
    update?.();
  }, [props.updateKey, update]);

  const ReferenceElement = props.referenceElementType ?? 'span';

  return (
    <>
      {React.createElement(ReferenceElement, {
        ref: setReferenceElement,
        style: {
          display: 'inline-flex',
          verticalAlign: 'middle',
          ...props.style,
        },
        'aria-describedby': props.describedBy,
        'aria-labelledby': props.labelledBy,
        children: props.content,
        ...props.referenceElementProps,
      })}
      {props.children(
        setPopperElement,
        styles.popper,
        attributes.popper?.['data-popper-placement']?.includes('start')
          ? 'left'
          : 'right',
        attributes.popper?.['data-popper-placement']?.includes('bottom')
          ? 'bottom'
          : 'top',
      )}
    </>
  );
};

export function placementFromPreferences(
  preferX: XAlign | undefined,
  preferY: YAlign | undefined,
): PopperJS.Placement | undefined {
  if (preferY) {
    if (preferX) {
      return `${preferY}-${preferX === 'left' ? 'start' : 'end'}` as any;
    } else {
      return preferY;
    }
  } else if (preferX) {
    return `auto-${preferX === 'left' ? 'start' : 'end'}` as any;
  } else {
    return undefined;
  }
}
