export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <rect
      x="2.15"
      y="2.87"
      width="43.7"
      height="42.27"
      rx="15"
      fill="url(#castro-gradient0)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.27 0H16.73c-4.81 0-7.22 0-9.82.82a10.2 10.2 0 00-6.09 6.1C0 9.5 0 11.91 0 16.72v14.54c0 4.81 0 7.22.82 9.82a10.2 10.2 0 006.1 6.09c2.59.82 5 .82 9.81.82h14.54c4.81 0 7.22 0 9.82-.82a10.2 10.2 0 006.09-6.1c.82-2.59.82-5 .82-9.81V16.73c0-4.81 0-7.22-.82-9.82a10.2 10.2 0 00-6.1-6.09C38.5 0 36.09 0 31.28 0zM44.9 24A20.87 20.87 0 0024.02 3.14 20.87 20.87 0 1044.91 24z"
      fill="url(#castro-gradient1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.64 35.8L29.17 31A8.7 8.7 0 0024 15.23a8.74 8.74 0 00-5.13 15.8l-3.47 4.8A14.6 14.6 0 019.3 23.94C9.31 15.87 15.9 9.31 24 9.31s14.69 6.56 14.69 14.64a14.6 14.6 0 01-6.05 11.85zm-8.6-15.38a3.53 3.53 0 110 7.06 3.53 3.53 0 010-7.06z"
      fill="url(#castro-gradient2)"
    />
    <defs>
      <linearGradient
        id="castro-gradient0"
        x1="-19.7"
        y1="2.87"
        x2="-19.7"
        y2="45.13"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#151515" />
        <stop offset="1" stopColor="#353535" />
      </linearGradient>
      <linearGradient
        id="castro-gradient1"
        x1="-24"
        y1="0"
        x2="-24"
        y2="48"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CCBF" />
        <stop offset="1" stopColor="#00B265" />
      </linearGradient>
      <linearGradient
        id="castro-gradient2"
        x1="-5.37"
        y1="9.31"
        x2="-5.37"
        y2="35.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".3" stopColor="#fff" />
        <stop offset="1" stopColor="#9CC0B7" />
      </linearGradient>
    </defs>
  </svg>
);
