export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <circle cx="24" cy="24" r="21.16" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 48a24 24 0 100-48 24 24 0 000 48zm0-5.8c3.7 0 7.13-1.1 10-3l-2.55-2.75a14.5 14.5 0 11.05-24.86v-.01l2.64-2.7A18.2 18.2 0 1024 42.2zm12.88-5.34l-2.64-2.58A14.46 14.46 0 0038.5 24c0-3.93-1.57-7.5-4.11-10.12l2.57-2.64A18.14 18.14 0 0142.2 24c0 5.02-2.04 9.57-5.32 12.86zm-15-5.64l9.86-5.62a1.85 1.85 0 000-3.2l-9.86-5.62a1.58 1.58 0 00-2.36 1.37v11.7a1.58 1.58 0 002.36 1.37z"
      fill="#4780E5"
    />
  </svg>
);
