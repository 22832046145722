export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <path
      d="M3.66 18.66c0-4.66 0-6.99.76-8.83a10 10 0 015.41-5.4c1.84-.77 4.17-.77 8.83-.77h10.27c4.66 0 7 0 8.83.76a10 10 0 015.41 5.41c.76 1.84.76 4.17.76 8.83v10.68c0 4.66 0 6.99-.76 8.83a10 10 0 01-5.41 5.4c-1.84.77-4.17.77-8.83.77H18.66c-4.66 0-6.99 0-8.83-.76a10 10 0 01-5.4-5.41c-.77-1.84-.77-4.17-.77-8.83V18.66z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15A15 15 0 0115 0h18a15 15 0 0115 15v18a15 15 0 01-15 15H15A15 15 0 010 33V15zm22.6 27.1c-1.44-.52-1.75-1.22-2.34-5.28-.68-4.73-.83-7.66-.44-8.59.53-1.23 1.96-1.92 3.96-1.93 1.98-.01 3.42.7 3.95 1.93.4.93.25 3.86-.43 8.59-.47 3.3-.73 4.14-1.37 4.7a3.31 3.31 0 01-3.33.57zM7.16 25.23a16.68 16.68 0 009.42 12.08c.85.4 1.65.75 1.76.75.17 0 .19-.21.1-.93-.12-1.18-.26-1.42-.94-1.7a15.3 15.3 0 01-8.06-9.95c-.37-1.5-.37-5.16 0-6.66A15.06 15.06 0 0120.82 7.8c1.6-.32 4.45-.32 6.03 0a14.94 14.94 0 0111.28 10.68c.47 1.69.52 5.35.1 6.99a15.23 15.23 0 01-8.06 9.92c-.67.28-.82.53-.96 1.7-.08.74-.07.93.1.93.5 0 3.48-1.6 4.7-2.52a16.96 16.96 0 006.5-10.57c.23-1.32.23-4.54-.01-5.71a16.5 16.5 0 00-4.55-8.77A16.54 16.54 0 0023.8 5.3c-4.74 0-9 1.83-12.3 5.3a16.78 16.78 0 00-4.4 8.59c-.28 1.3-.23 4.65.06 6.06zm9.4 5.52a12 12 0 01-3.8-6c-.37-1.39-.37-4.03.02-5.42a11.74 11.74 0 017.64-8.02c1.32-.46 4.24-.57 5.86-.21 5.6 1.23 9.56 6.8 8.79 12.32a11.22 11.22 0 01-2.44 5.74c-.68.86-2.32 2.3-2.6 2.3-.05 0-.1-.54-.1-1.2 0-.78.28-1.54.78-2.13l.05-.07a9.05 9.05 0 00-.5-12.42 8.73 8.73 0 00-4.87-2.49 6.25 6.25 0 00-2.91-.01 8.83 8.83 0 00-5.04 2.5 9.03 9.03 0 00-.53 12.42l.05.06c.5.6.78 1.36.78 2.14 0 .67-.06 1.22-.12 1.22a5.68 5.68 0 01-1.06-.73zm3.32-10.07a3.71 3.71 0 002.26 3.54c3.03 1.4 6.38-1.36 5.45-4.5-.31-1.02-1.11-2-2.1-2.51a4.24 4.24 0 00-3.38 0 3.9 3.9 0 00-2.23 3.47z"
      fill="url(#applepodcasts-gradient)"
    />
    <defs>
      <linearGradient
        id="applepodcasts-gradient"
        x1="48.26"
        y1="48.41"
        x2="47.85"
        y2="-.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#822CBE" />
        <stop offset="1" stopColor="#D772FB" />
      </linearGradient>
    </defs>
  </svg>
);
