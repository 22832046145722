export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 104 104" fill="none">
    <circle cx="52" cy="52" r="49" fill="#ffce00" />
    <path
      fill="#004a93"
      d="M65 16.1c-.6-.3-1.3-.2-1.9 0-.6.3-1.1.7-1.3 1.3-.3.6-.2 1.3 0 1.9.3.6.7 1.1 1.3 1.3a32.88 32.88 0 0120.4 30.5c0 18.2-14.8 33-33 33-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5a37.94 37.94 0 0014.5-73z"
    />
    <path
      fill="#004a93"
      d="M50.5 76.1c13.7 0 24.9-11.2 24.9-24.9S64.2 26.3 50.5 26.3c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5c11 0 20 9 20 20s-9 20-20 20c-1.4 0-2.5 1.1-2.5 2.5 0 1.1 1.1 2.3 2.5 2.3z"
    />
    <path
      fill="#004a93"
      d="M55.7 56.7c-.5.4-.8 1.1-.8 1.7 0 .7.2 1.3.7 1.8.5.5 1.1.8 1.8.8.6 0 1.2-.2 1.7-.7a12.5 12.5 0 00-8.5-21.7c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5c4.2 0 7.6 3.4 7.6 7.6-.1 2.1-1 4.1-2.5 5.5z"
    />
  </svg>
);
