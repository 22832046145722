import {PageTitle} from '@pinecast/common/Text';
import {ShareShortLink_podcast} from '@pinecast/gql/__generated_sl__/ShareShortLink';
import {useCSS} from '@pinecast/styles';
import {useIsMobile} from '@pinecast/common/hooks/useIsMobile';

import LatestEpisodePlayer from './LatestEpisodePlayer';
import ListenNow from './ListenNow';
import PodcastLinks from './PodcastLinks';

type Props = {
  podcast: ShareShortLink_podcast;
};

const PodcastChrome = ({podcast}: Props) => {
  const css = useCSS();
  const isMobile = useIsMobile();

  const titleAndSubtitle = (
    <div className={css({gridArea: 'title'})}>
      <PageTitle
        style={{
          lineHeight: '1.1',
          marginTop: isMobile ? '0' : '4px',
          marginBottom: '0',
        }}
      >
        {podcast.name}
      </PageTitle>
      <h2
        className={css({
          fontSize: isMobile ? '15px' : '16px',
          fontWeight: 500,
          lineHeight: '20px',
          marginTop: '4px',
          marginBottom: 0,
        })}
      >
        {podcast.subtitle || podcast.authorName}
      </h2>
    </div>
  );
  const description = (
    <p className={css({marginTop: '10px', marginBottom: 0})}>
      {podcast.description}
    </p>
  );
  const coverArt = (
    <img
      alt=""
      src={podcast.coverArtThumbnail}
      className={css({
        borderRadius: '4px',
        border: '1px solid #DCE7EC',
        gridArea: 'art',
      })}
      height={isMobile ? 70 : 140}
      width={isMobile ? 70 : 140}
    />
  );

  if (isMobile) {
    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          margin: '0',
          padding: '20px 12px',
        })}
      >
        <header
          className={css({
            display: 'grid',
            gap: '12px',
            gridTemplateAreas: "'art title'",
            gridTemplateColumns: '70px 1fr',
            gridTemplateRows: '1fr',
          })}
        >
          {coverArt}
          {titleAndSubtitle}
        </header>
        {description}
        <section className={css({marginTop: '12px'})}>
          <ListenNow podcast={podcast} />
          <LatestEpisodePlayer podcast={podcast} />
        </section>
        <PodcastLinks podcast={podcast} />
      </div>
    );
  } else {
    return (
      <div
        className={css({
          display: 'grid',
          gridGap: '20px',
          gridTemplateColumns: '140px 1fr',
          gridTemplateRows: 'min-content auto',
          gridTemplateAreas: '"nav header" "nav body"',
          margin: '0 auto',
          maxWidth: '1000px',
          padding: '20px',
        })}
      >
        <header className={css({gridArea: 'header'})}>
          {titleAndSubtitle}
          {description}
        </header>
        <section className={css({gridArea: 'nav'})}>
          {coverArt}
          <PodcastLinks podcast={podcast} />
        </section>
        <section className={css({gridArea: 'body'})}>
          <ListenNow podcast={podcast} />
          <LatestEpisodePlayer podcast={podcast} />
        </section>
      </div>
    );
  }
};

export default PodcastChrome;
