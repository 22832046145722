import styled, {CSS} from '@pinecast/styles';

const Wrapper = styled('div', {
  alignItems: 'inherit',
  display: 'inline-flex',
});

type Props = {
  allowWrap?: boolean;
  centerChildren?: boolean;
  children: JSX.Element | Array<JSX.Element | boolean | null | undefined>;
  role?: 'radiogroup';
  spacing: number;
  // style?: CSS;
  verticalSpacing?: number;
  wrapperStyle?: CSS;
};

const Group = ({
  allowWrap,
  centerChildren,
  children,
  role,
  spacing,
  // style,
  verticalSpacing = spacing,
  wrapperStyle,
}: Props) => (
  <Wrapper
    role={role}
    style={{
      alignItems: centerChildren ? 'center' : undefined,
      flexWrap: allowWrap ? 'wrap' : undefined,
      gap: `${verticalSpacing}px ${spacing}px`,
      verticalAlign: centerChildren ? 'middle' : undefined,
      ...wrapperStyle,
    }}
  >
    {children}
  </Wrapper>
);

export default Group;

export const VerticalGroup = ({
  centerChildren,
  children,
  role,
  spacing,
  // style,
  wrapperStyle,
}: Omit<Props, 'verticalSpacing'>) => (
  <Wrapper
    role={role}
    style={{
      alignItems: centerChildren ? 'center' : 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: `${spacing}px`,
      ...wrapperStyle,
    }}
  >
    {children}
  </Wrapper>
);
