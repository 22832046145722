import {Link as RouterLink} from 'react-router-dom';
import * as React from 'react';

import {CSS, useColors, useCSS} from '@pinecast/styles';

type AnchorProps = Omit<
  React.AllHTMLAttributes<HTMLAnchorElement>,
  'href' | 'onClick' | 'style' | 'target'
>;

type LinkProps = AnchorProps & {
  forceHardNavigation?: boolean;
  href: string;
  rel?: 'payment';
  style?: CSS;
  target?: '_blank';
};

type ButtonProps = AnchorProps & {
  onClick: () => void;
};

const Link = ({
  children,
  forceHardNavigation,
  style,
  ...rest
}: Omit<React.HTMLProps<HTMLAnchorElement>, 'style'> & {
  forceHardNavigation?: boolean;
  style?: CSS;
} & React.RefAttributes<any>) => {
  const css = useCSS();
  const designColors = useColors();

  const className = css(
    {
      color: designColors.headingBlue,
      cursor: 'pointer',
      textDecoration: 'underline',
      transition: 'color 0.2s',

      ':hover': {
        color: designColors.borderGrayAccent,
      },
      ...style,
    },
    `${rest.className ?? ''} Link`.trim(),
  );
  if (rest.href && !rest.href.includes('//') && !forceHardNavigation) {
    const {href: to, ...other} = rest;
    return (
      <RouterLink {...other} className={className} to={to!}>
        {children}
      </RouterLink>
    );
  }
  return (
    <a {...rest} className={className}>
      {children}
    </a>
  );
};

function AnchorLink(props: LinkProps) {
  return (
    <Link
      {...props}
      rel={props.target ? `noopener noreferrer ${props.rel || ''}` : props.rel}
    />
  );
}

class LinkButton extends React.PureComponent {
  props: ButtonProps;
  handleClick = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick();
  };
  render() {
    return <Link {...this.props} onClick={this.handleClick} />;
  }
}

export default (props: LinkProps | ButtonProps) => {
  if (props.hasOwnProperty('href')) {
    return <AnchorLink {...(props as LinkProps)} />;
  }
  return <LinkButton {...(props as ButtonProps)} />;
};
