import * as React from 'react';

const queries = new Map<string, [number, MediaQueryList]>();

function getQuery(query: string): MediaQueryList {
  if (!queries.has(query)) {
    const mql = window.matchMedia(query);
    queries.set(query, [1, mql]);
    return mql;
  }

  const mql = queries.get(query)!;
  mql[0] += 1;
  return mql[1];
}
function freeQuery(query: string) {
  if (!queries.has(query)) {
    throw new Error(`Query ${query} had been prematurely freed`);
  }
  const mql = queries.get(query)!;
  mql[0] -= 1;
  if (mql[0] === 0) {
    queries.delete(query);
  }
}
function peekQuery(query: string) {
  const q = getQuery(query);
  freeQuery(query);
  return q;
}

export type Breakpoint<T extends string> = {
  key: T;
  query: string;
};

function getKey<T extends string>(breakpoints: Array<Breakpoint<T>>): T | null {
  for (const breakpoint of breakpoints) {
    if (peekQuery(breakpoint.query).matches) {
      return breakpoint.key;
    }
  }
  return null;
}
export const useViewportSize = <T extends string>(
  breakpoints: Array<Breakpoint<T>>,
): T | null => {
  const [currentKey, setKey] = React.useState(() => getKey(breakpoints));
  React.useEffect(() => {
    breakpoints.forEach(breakpoint => getQuery(breakpoint.query));
    return () => {
      breakpoints.forEach(breakpoint => freeQuery(breakpoint.query));
    };
  }, [breakpoints]);
  React.useEffect(() => {
    const check = () => {
      setKey(getKey(breakpoints));
    };
    breakpoints.forEach(breakpoint => {
      peekQuery(breakpoint.query).addListener(check);
    });
    return () => {
      breakpoints.forEach(breakpoint =>
        peekQuery(breakpoint.query).removeListener(check),
      );
    };
  }, [breakpoints]);

  return currentKey;
};

type Props = {
  children: (key: string | null) => React.ReactNode;
  breakpoints: Array<Breakpoint<string>>;
};
const ViewportSizeListener = ({breakpoints, children}: Props) => {
  const currentKey = useViewportSize(breakpoints);
  return children(currentKey);
};

export default ViewportSizeListener as React.FC<Props>;
