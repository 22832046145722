import * as React from 'react';

import {useIsDarkMode} from '@pinecast/common/hooks/useIsDarkMode';
import {useColors} from '@pinecast/styles';

const IHeartRadio = ({
  size = 48,
  style,
}: {
  size?: number;
  style?: React.CSSProperties;
}) => {
  const isDarkMode = useIsDarkMode();
  const designColors = useColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      style={style}
    >
      <g>
        <path
          d="M8.34 43.97c1.2 0 2.05-.62 2.05-1.64v-.02c0-.98-.75-1.57-2.03-1.57H5.9v3.23h2.44zM5 40.37a.45.45 0 01.44-.46h2.98c.98 0 1.77.3 2.27.8.39.38.61.94.61 1.56v.03c0 1.3-.88 2.06-2.12 2.3l1.97 2.55c.1.1.15.2.15.33 0 .24-.22.44-.45.44-.18 0-.32-.1-.42-.24l-2.24-2.9h-2.3v2.69a.46.46 0 01-.45.45.45.45 0 01-.44-.45v-7.1zm13.37 4.57l-1.84-4.06-1.83 4.06h3.67zm-5.64 2.33l3.24-7.05c.1-.25.28-.4.56-.4h.05c.27 0 .46.15.56.4l3.23 7.04c.03.08.05.16.05.23a.43.43 0 01-.43.43c-.21 0-.36-.15-.44-.33l-.83-1.83h-4.37l-.83 1.85c-.08.2-.23.3-.43.3a.4.4 0 01-.41-.4c0-.07 0-.15.05-.24zm12.18-.24c2 0 3.3-1.36 3.3-3.12v-.02c0-1.76-1.3-3.15-3.3-3.15h-1.87v6.3h1.87zm-2.77-6.66a.45.45 0 01.44-.46h2.33c2.5 0 4.23 1.72 4.23 3.95v.03c0 2.24-1.73 3.97-4.23 3.97h-2.33a.45.45 0 01-.44-.45v-7.04zm8.98-.06a.45.45 0 01.44-.46.46.46 0 01.45.46v7.16a.46.46 0 01-.45.45.45.45 0 01-.44-.45V40.3zm10.06 3.6v-.02c0-1.81-1.32-3.29-3.14-3.29s-3.12 1.46-3.12 3.26v.03c0 1.8 1.33 3.28 3.14 3.28 1.82 0 3.12-1.45 3.12-3.26zm-7.19 0v-.02c0-2.2 1.65-4.12 4.07-4.12a4 4 0 014.06 4.1v.02c0 2.19-1.65 4.11-4.08 4.11A4 4 0 0134 43.91"
          fill={isDarkMode ? designColors.contentTextAccent : '#919395'}
        />
        <path
          d="M38.06 9.53c0 2.79-1.51 5.54-4.5 8.18a.7.7 0 11-.9-1.03c2.66-2.37 4.02-4.77 4.02-7.15v-.04c0-1.93-1.22-4.2-2.77-5.17a.7.7 0 11.74-1.17 8.04 8.04 0 013.4 6.34v.04zm-8.98 5.82a.7.7 0 01-.67-1.2 5.2 5.2 0 002.68-4.33 4.19 4.19 0 00-2.25-3.71.7.7 0 11.64-1.22 5.58 5.58 0 013 4.92v.02a6.62 6.62 0 01-3.4 5.52zm-5.55-2.02a3 3 0 110-6 3 3 0 010 6zm-4.6 1.76a.7.7 0 01-.95.26 6.61 6.61 0 01-3.39-5.52V9.8a5.57 5.57 0 013-4.92.7.7 0 01.64 1.22 4.2 4.2 0 00-2.26 3.7 5.2 5.2 0 002.69 4.34.69.69 0 01.26.94zm-4.46 2.56a.7.7 0 01-.97.06C10.52 15.07 9 12.3 9 9.53v-.04c0-2.4 1.47-5.13 3.42-6.34a.7.7 0 01.73 1.17 6.65 6.65 0 00-2.76 5.17v.04c0 2.38 1.35 4.78 4.03 7.15a.69.69 0 01.05.97zM31.87 0a10.1 10.1 0 00-8.34 4.5A9.99 9.99 0 005.21 9.96c0 3.5 2.22 6.02 4.53 8.35l9.05 8.54a.74.74 0 001.25-.54v-6.66a3.49 3.49 0 016.98 0v6.66a.74.74 0 001.26.54l9.04-8.54c2.32-2.33 4.53-4.85 4.53-8.34A9.95 9.95 0 0031.87 0"
          fill="#C6002B"
        />
        <path
          d="M8.7 29.53h2.4v3.23h3.06v-3.23h2.4v8.62h-2.4v-3.28H11.1v3.28H8.7v-8.62zm13.07 4.7c0-.7-.51-1.17-1.15-1.17-.62 0-1.1.48-1.16 1.18h2.31zm-4.53.62v-.03c0-1.94 1.4-3.48 3.36-3.48 2.3 0 3.37 1.69 3.37 3.64 0 .15 0 .32-.02.48H19.5c.19.73.72 1.1 1.45 1.1.55 0 1-.21 1.47-.68l1.3 1.07a3.5 3.5 0 01-2.9 1.36c-2.1 0-3.57-1.4-3.57-3.46zm14.6-3.36h2.34v1.34c.38-.91 1-1.5 2.1-1.45v2.46h-.2c-1.21 0-1.9.7-1.9 2.24v2.07h-2.34v-6.66zm5.9 4.59v-2.7h-.78v-1.9h.79V29.8h2.34v1.69h1.55v1.88H40.1v2.25c0 .47.21.67.64.67.3 0 .6-.09.89-.22v1.83c-.42.23-.98.38-1.63.38-1.4 0-2.24-.61-2.24-2.2zM6.42 28.9a1.24 1.24 0 100 2.49 1.24 1.24 0 000-2.49zm.04 3.07h-.08a1.22 1.22 0 00-1.23 1.22v4.95h2.53V33.2c0-.68-.54-1.22-1.22-1.22zm20.99 4.84c-.6.02-.97-.33-.97-.75 0-.4.32-.61.91-.61h1.34v.07c-.01.28-.12 1.24-1.28 1.29zm3.5-2.02c0-2.07-1.48-3.46-3.58-3.46a3.5 3.5 0 00-2.9 1.36l1.3 1.07c.48-.47.92-.68 1.48-.68.72 0 1.24.36 1.43 1.07v.05h-1.54c-1.91.03-2.92.75-2.92 2.1 0 1.3.93 2 2.53 2 .87 0 1.53-.34 1.98-.8v.64h2.21V34.8z"
          fill={isDarkMode ? designColors.contentText : '#101820'}
        />
      </g>
    </svg>
  );
};

export default IHeartRadio;
