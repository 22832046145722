import * as React from 'react';

import {useIsDarkMode} from '@pinecast/common/hooks/useIsDarkMode';

const designColors = {
  background: '#F8F4EB',

  contentBackground: '#fff',
  contentBorder: '#adacaa',
  contentText: '#090909',
  contentTextAccent: '#090909',

  inputBorder: '#888',

  accentBlue: '#090909',
  accentBlueLight: '#C7ECFA',
  accentBlueText: '#fff',
  borderBlueLight: '#E3F5FC',
  borderBlueNormal: '#C7ECFA',
  borderGrayAccent: '#5F7680',
  borderGrayLight: '#EDF4FF',
  borderGrayNormal: '#888',
  borderRedAccent: '#F05569',
  borderRedLight: '#FFDEE2',
  headingBlue: '#090909',
  borderMagentaNormal: '#DF0D7E',
  borderGreenNormal: '#97DEA7',
  borderGreenAccent: '#6DDA85',

  badgeBlueText: '#335867',
  badgeBlueBackground: '#C7ECFA',
  badgeGrayText: '#44484d',
  badgeGrayBackground: '#e9edf1',
  badgeGreenText: '#004910',
  badgeGreenBackground: '#C4FF7E',
  badgeRedText: '#7E0B49',
  badgeRedBackground: '#FF92A1',
  badgeYellowText: '#73611a',
  badgeYellowBackground: '#F9F199',

  promotionText: '#090909',
  promotionBackground: '#F9F199',
};
export type ColorMap = typeof designColors;

const designColorsDark: ColorMap = {
  ...designColors,

  background: '#090909',

  contentBackground: '#26262a',
  contentBorder: '#555',
  contentText: '#fff',
  contentTextAccent: '#ccc',

  inputBorder: '#888',

  accentBlue: '#0066FF',
  accentBlueLight: '#2f6196',
  accentBlueText: '#fff',

  borderBlueLight: '#28408C',
  borderBlueNormal: '#4B6884',
  borderGrayAccent: '#6e6e6e',
  borderGrayLight: '#555',
  borderGrayNormal: '#69778c',
  borderRedAccent: '#F05569', // TODO
  borderRedLight: '#FFDEE2', // TODO
  headingBlue: '#2d8aff',
  borderMagentaNormal: '#DF0D7E', // TODO
  borderGreenNormal: '#02a552',
  borderGreenAccent: '#6DDA85', // TODO

  badgeBlueBackground: '#1a2d4a',
  badgeBlueText: '#78aefb',
  badgeGrayText: '#b8cce0',
  badgeGrayBackground: '#394b61',
  badgeGreenBackground: '#095a1b',
  badgeGreenText: '#79ff97',
  badgeRedBackground: '#6b261f',
  badgeRedText: '#ff809e',
  badgeYellowText: '#ffe266',
  badgeYellowBackground: '#675a03',

  promotionText: '#ffedba',
  promotionBackground: '#86660f',
};

const ColorsContext = React.createContext(designColors);
export const ColorsProvider = ColorsContext.Provider;
export const useColors = () => React.useContext(ColorsContext);

export const DarkModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const darkMode = useIsDarkMode();
  return React.createElement(
    ColorsProvider,
    {value: darkMode ? designColorsDark : designColors},
    children,
  );
};

export const useSetBackground = () => {
  const colors = useColors();
  React.useLayoutEffect(() => {
    document.body.style.backgroundColor = colors.background;
    document.body.style.color = colors.contentText;
    document.body.parentElement!.style.backgroundColor = colors.background;
    document.body.parentElement!.style.color = colors.contentText;
  });
};
