export const DEFAULT_FONT =
  '"Monument Grotesk", -apple-system, BlinkMacSystemFont, sans-serif';
export const DEFAULT_FONT_CSS_IMPORT = `
@font-face {
  font-family: "Ginto Nord Condensed";
  font-weight: 400;
  src: url("https://js.pinecast.net/fonts/ginto-nord-condensed.woff2")
      format("woff2");
}
@font-face {
  font-family: "Monument Grotesk";
  font-weight: 400;
  src: url("https://js.pinecast.net/fonts/monument-grotesk-regular.woff2") format("woff2");
}
@font-face {
  font-family: "Monument Grotesk Bold";
  font-weight: 400;
  src: url("https://js.pinecast.net/fonts/monument-grotesk-bold.woff2") format("woff2");
}
@font-face {
  font-family: "Monument Grotesk Semi-Mono";
  font-weight: 400;
  src: url("https://js.pinecast.net/fonts/monument-grotesk-semimono.woff2") format("woff2");
}
`;
