import * as React from 'react';

const registered = new Map<string, Array<() => void>>();

export const useEventBus = (name: string, cb: () => void) => {
  React.useEffect(() => {
    if (!registered.has(name)) {
      registered.set(name, []);
    }
    registered.get(name)!.push(cb);
    // console.debug(
    //   `${name} now contains ${registered.get(name)!.length} after addition`,
    // );
    return () => {
      const list = registered.get(name)!;
      list.splice(list.indexOf(cb), 1);
      // console.debug(
      //   `${name} now contains ${registered.get(name)!.length} after removal`,
      // );
      if (list.length === 0) {
        registered.delete(name);
      }
    };
  }, [cb, name]);

  return React.useCallback(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(
        `Firing ${name} to ${registered.get(name)!.length} callbacks`,
      );
    }
    [...registered.get(name)!].forEach(cb => cb());
  }, [name]);
};
