const _uppercasePattern = /([A-Z])/g;
const msPattern = /^ms-/;

function hyphenate(string: string) {
  return string.replace(_uppercasePattern, '-$1').toLowerCase();
}
function hyphenateStyleName(string: string) {
  return hyphenate(string).replace(msPattern, '-ms-');
}

const isUnitlessNumber = {
  animationIterationCount: true,
  borderImageOutset: true,
  borderImageSlice: true,
  borderImageWidth: true,
  boxFlex: true,
  boxFlexGroup: true,
  boxOrdinalGroup: true,
  columnCount: true,
  columns: true,
  flex: true,
  flexGrow: true,
  flexPositive: true,
  flexShrink: true,
  flexNegative: true,
  flexOrder: true,
  gridRow: true,
  gridRowEnd: true,
  gridRowSpan: true,
  gridRowStart: true,
  gridColumn: true,
  gridColumnEnd: true,
  gridColumnSpan: true,
  gridColumnStart: true,
  fontWeight: true,
  lineClamp: true,
  lineHeight: true,
  opacity: true,
  order: true,
  orphans: true,
  tabSize: true,
  widows: true,
  zIndex: true,
  zoom: true,

  // SVG-related properties
  fillOpacity: true,
  floodOpacity: true,
  stopOpacity: true,
  strokeDasharray: true,
  strokeDashoffset: true,
  strokeMiterlimit: true,
  strokeOpacity: true,
  strokeWidth: true,

  WebkitLineClamp: true,
};

export const unitlessCSSProperties = new Set<string>(
  Object.keys(isUnitlessNumber).map(name => hyphenateStyleName(name)),
);

// NOTE: This is a special exception, because most line heights would be passed
// as numbers and we want them to have a pixel value. Instead, it should be
// deliberate when setting a relative line height.
unitlessCSSProperties.delete('line-height');

export function compile(rule: string): string {
  const [prop, val] = rule.split(':', 2);
  return `${prop}:${applyUnitToHyphenated(prop, val)}px`;
}
export function applyUnitToHyphenated(prop: string, value: any): any {
  if (
    !/^-?\d+(\.\d+)?$/.exec(value) ||
    unitlessCSSProperties.has(prop) ||
    value === 0
  ) {
    return String(value);
  }
  return `${value}px`;
}
export function applyUnit(prop: string, value: any): any {
  if (
    !/^-?\d+(\.\d+)?$/.exec(value) ||
    (prop in isUnitlessNumber && prop !== 'lineHeight') ||
    value === 0
  ) {
    return String(value);
  }
  return `${value}px`;
}
