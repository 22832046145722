export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <circle cx="24" cy="24" r="23.52" fill="#E10000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 0a24 24 0 10.02 48.02A24 24 0 0024 0zm0 46.22A22.2 22.2 0 011.78 24 22.2 22.2 0 0124 1.78 22.2 22.2 0 0146.22 24 22.2 22.2 0 0124 46.22z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.6 29.9a22.51 22.51 0 00.52-10.03 22.4 22.4 0 00-4.46-9.7c-.48-.57-.96-1.1-1.44-1.58-.3-.24-.53-.24-.82 0a38 38 0 00-1.45 1.39l-.9.87a1 1 0 00-.2.29c-.04.14.05.28.15.43.14.19.34.38.53.57a17.63 17.63 0 013.74 6.58c.77 2.4.96 4.9.58 7.4a17.5 17.5 0 01-1.01 4.07 16.85 16.85 0 01-3.94 6.2c-.28.23-.28.52 0 .76.24.2.48.43.72.67.58.53 1.1 1.06 1.64 1.59.24.24.48.24.72.05l.28-.3a20.73 20.73 0 003.8-5.32c.62-1.3 1.15-2.54 1.53-3.94zm-9.85.39A14.57 14.57 0 0030.24 24c0-1.2-.14-2.35-.48-3.5a14.84 14.84 0 00-3.65-6.63c-.19-.19-.38-.19-.62-.05-.05 0-.1.05-.15.1l-1.15 1.1c-.38.38-.77.75-1.15 1.1-.24.3-.29.54 0 .82a10 10 0 012.35 4.08c.43 1.35.53 2.7.34 4.08-.24 1.78-.87 3.41-1.92 4.9l-.87 1c-.19.25-.14.49.05.68.82.82 1.59 1.58 2.4 2.35.24.24.48.24.72 0l.1-.1a12.8 12.8 0 002.54-3.64zm-8.06-6.82a6.96 6.96 0 00-.53-2.4 6.11 6.11 0 00-2.69-3.12 6.73 6.73 0 00-3.45-.86H9.5c-.28 0-.38.1-.38.43v13.39c0 .2.1.29.34.29h4.12c.77 0 1.44-.14 2.12-.29a5.85 5.85 0 004.46-3.74c.48-1.2.62-2.45.53-3.7zm-8.83-3.45v7.68c0 .28.14.38.48.38h1.39c.48 0 .96-.1 1.44-.29a3.06 3.06 0 001.68-1.77c.43-1.16.43-2.55.1-3.75-.44-1.44-1.5-2.59-3.03-2.59h-1.63c-.29 0-.43.05-.43.34z"
      fill="#fff"
    />
  </svg>
);
