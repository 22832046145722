export default ({size = 48}: {size?: number}) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <circle cx="24" cy="24" r="24" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 24a24 24 0 11-48 0 24 24 0 0148 0zM20.04 8.57a3.27 3.27 0 111.62 6.34A9.39 9.39 0 1033.1 26.34a3.27 3.27 0 016.34 1.62 15.93 15.93 0 11-19.39-19.4zm11.93 13.65c.7-.37.98-1.25.6-1.96a11.54 11.54 0 00-4.97-4.9 1.45 1.45 0 00-1.32 2.58c1.59.81 2.9 2.1 3.72 3.67.37.71 1.25.98 1.96.61zm5.5-2.42a1.45 1.45 0 11-2.77.87 11.23 11.23 0 00-7.3-7.35 1.45 1.45 0 01.88-2.77 14.14 14.14 0 019.2 9.25zm3.92 1.03c.76-.24 1.2-1.05.95-1.82a20.17 20.17 0 00-13.11-13.2 1.45 1.45 0 10-.88 2.78 17.26 17.26 0 0111.21 11.29c.24.76 1.06 1.19 1.83.95z"
      fill="#C7001C"
    />
  </svg>
);
